exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-preview-conversation-jsx": () => import("./../../../src/pages/preview-conversation.jsx" /* webpackChunkName: "component---src-pages-preview-conversation-jsx" */),
  "component---src-pages-preview-index-jsx": () => import("./../../../src/pages/preview-index.jsx" /* webpackChunkName: "component---src-pages-preview-index-jsx" */),
  "component---src-templates-conversations-jsx": () => import("./../../../src/templates/conversations.jsx" /* webpackChunkName: "component---src-templates-conversations-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-infographic-jsx": () => import("./../../../src/templates/infographic.jsx" /* webpackChunkName: "component---src-templates-infographic-jsx" */),
  "component---src-templates-infographics-jsx": () => import("./../../../src/templates/infographics.jsx" /* webpackChunkName: "component---src-templates-infographics-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-shop-jsx": () => import("./../../../src/templates/shop.jsx" /* webpackChunkName: "component---src-templates-shop-jsx" */)
}

